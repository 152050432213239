import { Button, ChakraProvider, Heading, Input, Text } from '@chakra-ui/react'
import axios from 'axios';
import { useEffect, useState } from 'react';

function App() {
  const [password, setPassword] = useState('');
  const [token, setToken] = useState('');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if(window.location.pathname.includes('resetPassword'))
    setToken(window.location.pathname.replace('/resetPassword/', ''))
  }, []);

  const saveHandler = async () => {
    try {
      await axios.post('https://api-v2.rollmops.app/auth/reset-verify', {
        token,
        password
      })
      setSuccess(true);
    } catch(e) {
      setError(true);
    }
  }

  if (!token)
    return (
      <ChakraProvider>
        <div style={{padding: 10}}>
          <Heading>Nieprawidłowy link</Heading>
        </div>
      </ChakraProvider>
    )

  if (success) {
    return (
      <ChakraProvider>
        <div style={{padding: 10}}>
          <Heading>Prawidłowo zmieniono hasło</Heading>
          <Text marginTop={5}>Zaloguj się w aplikacji nowym hasłem.</Text>
        </div>
      </ChakraProvider>
    )
  }

  if (error) {
    return (
      <ChakraProvider>
        <div style={{padding: 10}}>
          <Heading>Wystąpił problem ze zmianą hasła</Heading>
        </div>
      </ChakraProvider>
    )
  }

  return (
    <ChakraProvider>
      <div style={{padding: 10}}>
        <Heading>Zmiana hasła</Heading>
        <Input
          placeholder="Nowe hasło"
          marginTop={10}
          value={password}
          onChange={e => setPassword(e.target.value)}
        />
        <Button width="100%" marginTop={10} onClick={saveHandler}>Zmień hasło</Button>
      </div>
    </ChakraProvider>
  );
}

export default App;
